<template>
  <div class="container contact">
    <h2>Helpdesk System</h2>
    <div class="col-md-6">
      <div class="panel panel-info">
        <div class="panel-heading" style="background:#00796B;color:white;">
          <div class="panel-title">User Login</div>
        </div>
        <div style="padding-top:30px" class="panel-body">
          <div v-if="errorMessage !== ''" id="login-alert" class="alert alert-danger col-sm-12">{{ errorMessage }}</div>
          <form @submit.prevent="handleLogin" class="form-horizontal" role="form">
            <div style="margin-bottom: 25px" class="input-group">
              <span class="input-group-addon"><i class="glyphicon glyphicon-user"></i></span>
              <input v-model="email" type="text" class="form-control" name="email" placeholder="email" style="background:white;" required>
            </div>
            <div style="margin-bottom: 25px" class="input-group">
              <span class="input-group-addon"><i class="glyphicon glyphicon-lock"></i></span>
              <input v-model="password" type="password" class="form-control" name="password" placeholder="password" required>
            </div>
            <div style="margin-top:10px" class="form-group">
              <div class="col-sm-12 controls">
                <button type="submit" name="login" class="btn btn-success">Login</button>
              </div>
            </div>
            <div style="margin-top:10px" class="form-group">
              <div class="col-sm-12 controls">
                Admin: admin@webdamn.com<br>
                password: 123<br><br>
                User: smith@webdamn.com<br>
                password: 123
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      email: '',
      password: '',
      errorMessage: ''
    }
  },
  methods: {
    handleLogin () {
      const formData = {
        email: this.email,
        password: this.password
      }

      axios.post('https://helpdesk.mustardpcservices.co.uk/api/login.php', formData)
        .then((response) => {
          if (response.data.success) {
            // Login successful, perform any actions here (e.g., redirect to dashboard)
            this.errorMessage = '' // Clear any previous error messages
          } else {
            // Login failed, display the error message
            this.errorMessage = response.data.message
          }
        })
        .catch((error) => {
          console.error('Error while logging in:', error)
          this.errorMessage = 'An error occurred while logging in.'
        })
    }
  }
}
</script>
